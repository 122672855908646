import * as React from "react"
import {Trans} from 'gatsby-plugin-react-i18next';
import {graphql} from 'gatsby';

import Layout from "../components/layout"
import Seo from "../components/seo"
import LanguageMenu from "../components/language-menu";
import logo from "../images/allianz-logo.svg";


const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="404: Not found" />
      <header className="snap-start w-screen relative text-center flex bg-stage bg-fixed bg-cover bg-center">
        <div className="absolute w-full top-5">
          <LanguageMenu />
        </div>
        <div className="m-auto my-20 items-center">
          <a href="/" className="block">
            <img
              src={logo}
              alt="Allianz Logo"
              className="w-40 mb-12 m-auto"
            />
          </a>

          <h1 className="text-primary text-4xl font-bold uppercase">
            <Trans>notFoundTitle</Trans>
          </h1>
        </div>
      </header>

      <main className="min-h-screen container mx-auto max-w-4xl">
        <div className="px-10 pb-10 text-black lg:prose-xl text-center">
          <h2><Trans>notFoundHeadline</Trans></h2>
        </div>

        <div className="px-10 py-8 text-black lg:prose-xl text-center">
          <Trans>notFoundText</Trans>
        </div>

        <div className="px-10 text-black lg:prose-xl text-center">
          <Trans>notFoundText2</Trans>
          {" "}<a href="/">milestones-allianz-it.com</a>{" "}
          <Trans>notFoundText3</Trans>
        </div>
      </main>

    </Layout>
  )
}

export default NotFoundPage


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
